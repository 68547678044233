var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"-SJQenyI87BZ33_xpm6Sn"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/modules/account/master/fc76d6618ca0e0d916d7e3ce2180a16b2289527a";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://cd79a2afce454dbf9cbb09e4773fcf95@o828979.ingest.sentry.io/5882372',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  environment: process.env.APP_ENV === 'production' ? 'production' : 'staging',
  integrations: [new Sentry.BrowserTracing()],
  ignoreErrors: [
    /Abstract type "SearchParamReturnType" must resolve/,
    /Relay fetch error: You should be logged in for this/,
    /NetworkError when attempting to fetch resource/,
    /Failed to fetch/,
    /401: UNAUTHORIZED/,
    /^Relay fetch error: Token not found$/,
    /^Illegal invocation$/,
    /Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops./,
    /Minified exception occurred; use the non-minified dev environment for the full error message and additional helpful warnings./,
    /Event `Event` (type=error) captured as promise rejection./,
  ],
  //
  // Only report errors from whole URLs matching a regex pattern or an exact string.
  // whitelistUrls should match the URL of our actual JavaScript files.
  // It should match the URL of your site if and only if you are inlining code inside &lt;script&gt; tags.
  //
  allowUrls: [
    /^https:\/\/static\.kiwi.com\/modules\/account/gi, // This is for production
    /fe-cloudrun.kiwi.com/gi, // This is for staging
  ],
});
